@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.list {
  @media (min-width: breakpoint("small")) {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    @include stretch(math.div(size("gutter"), 2));
  }

 &Item {
    animation: fadeIn transition("duration--organisms") transition("ease-ease")
      forwards;
    opacity: 0;

    @media (min-width: breakpoint("small")) {
      display: flex;
      align-items: stretch;
      flex: 0 0 50%;
    }

    @media (min-width: breakpoint("medium")) {
      flex: 0 0 33.33%;
    }

    @media (min-width: breakpoint("small")) {
      padding: 0 math.div(size("gutter"), 2);
    }

    padding-bottom: size("gutter") !important;
  }
}