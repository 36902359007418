@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 0.3; }
}

@keyframes slideDown {
  0% { opacity: .7; transform: translateY(-30px); }
  100% { opacity: 1; transform: translateY(0); }
}


.container {
  height: size("header-height");
  position: static;

  @media (max-width: (breakpoint("small") - 1px)) {
    display: none;
  }
}

.block {
  z-index: z-index("system") + 2;
  height: 100%;

  flex: 1 0 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  position: static;
  background-color: var(--background);

  padding: 0 size("gutter--large");
}

.logo {
  flex: 0 0 auto;
}

.nav {
  display: flex;
  align-items: stretch;
  position: static;
}

.login {
  margin-left: size("gutter--large");
}

.logout {
  font-weight: bold;
  font-size: size("font--small");
}

.item {
  font-size: size("font--small");
  font-weight: 700 !important;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  height: 100%;
  display: flex;
  align-items: center;

  &:hover:not(.item__customerBadge) {
    text-decoration: underline;
  }

  &.active:not(.item__customerBadge) {
    text-decoration: underline;
    position: static;
  }

  &__customerBadge {
    color: var(--primary);
    
    &:hover {
      text-decoration: none;

      p:first-child {
        text-decoration: underline;
      }
    }

    &.active {
      p:first-child {
        text-decoration: underline;
      }
    }
  }

  & + & {
    margin-left: size("gutter--larger");
  }

  svg + span {
    margin-left: size("gutter--mini");
  }

  &__categories {
    color: var(--text);

    .chevron {
      transition: all transition("duration--organisms") transition("ease-ease");
      top: 0px;
      transform: rotate(90deg);
      margin-left: size("gutter--mini");
    }
  }

  @media (min-width: breakpoint("small")) and (max-width: (breakpoint("medium") - 1px)) {
    font-size: 14px;
  }
}

.item.active .chevron {
  transform: rotate(90deg) scaleX(-1);
}

.searchIcon {
  margin-right: size("gutter--mini");
  width: 22px;
  height: 22px;
}

.triangle {
  $triangle-size: .6em;
  @include triangle(top, $triangle-size, var(--light));

  left: 50%;
  margin-left: -$triangle-size;
  top: size("sub-header-height") + 2px;

  display: none;

  .item.active & {
    display: block;
  }
}

.brands {
  color: var(--text) !important;
}

.dim {
  position: fixed;
  top: size("header-height");
  bottom: 0;
  left: 0;
  right: 0;
  z-index: z-index("system");
  background: #000;

  animation: fadeIn transition("duration--organisms") transition("ease-out") forwards;
}

.expiresNotice {
  &:hover {
    text-decoration: none;

    > .notice {
      text-decoration: underline;
    }
  }
}

.exclamation {
  font-size: size("font--large");
}

.notice {
  font-size: size("font--mini");
  display: flex;
  flex-direction: column;
  margin-left: size("gutter--mini");

  .points {
    font-weight: 500;
  }
}

.districtName {
  margin-left: size("gutter--small");
  font-size: size("font--medium");
}

.menuItem {
  display: block;
}

.linksNav {
  position: relative;
  z-index: z-index("system") + 1;
  background: var(--secondary);
  padding: size("gutter--small") size("gutter--large");
  justify-content: flex-end;
  align-items: center;

  .item {
    color: var(--invertedText);
  }
}

.navDropdown {
  position: relative;

  &:hover {

    .navDropdownLinks {
      display: flex;
    }
  }


  .navDropdownLinks {
    padding-top: size("gutter--mini");
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background: var(--secondary);
    display: none;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    z-index: z-index("overlay") + 1;

    .item {
      padding: size("gutter--small") size("gutter--large");
      border-bottom: solid #fff 2px;
      margin-left: 0px;
      justify-content: center;
    }
  }
}

.navDropdown {
  position: relative;

  &:hover {

    .navDropdownLinks {
      display: flex;
    }
  }


  .navDropdownLinks {
    padding-top: size("gutter--mini");
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background: var(--secondary);
    display: none;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    z-index: z-index("overlay") + 1;

    .item {
      padding: size("gutter--small") size("gutter--large");
      border-bottom: solid #fff 2px;
      margin-left: 0px;
      justify-content: center;
    }
  }
}

.adminBtnContainer {
  align-self: center;
  margin-right: size("gutter--small");

  button {
    padding: size("gutter--mini") size("gutter") !important;
    font-size: size("font--small") !important;
  }
}