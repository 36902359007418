@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$height: 1rem + (size("input-padding") * 2);
$inset: 1rem;
$background: #fff;
$disabledBackground: color("disabled");
$readOnlyBackground: $background;
$transition: border-color transition("duration--molecules"),
  color transition("duration--molecules");
$coverFill: inset 0 0 0 $height;
$blockPaddingBottom: 1.8rem;
$floatLabelSize: 0.7em;
$floatLabelOffset: -0.6rem;
$icon-size: 20px;

@if size("input-icon-size") != null {
  $icon-size: size("input-icon-size");
}

.block {
  display: block;
  padding-bottom: $blockPaddingBottom;
}

.label {
  display: block;
  margin-bottom: math.div(size("gutter--small"), 1.5);

  .float & {
    z-index: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: 0;
    left: $inset;
    transform: translateY(0);
    transition: transform transition("duration--molecules"),
      font-size transition("duration--molecules");
    pointer-events: none;
    display: flex;
    align-items: center;
    color: color("border");
  }

  .error & {
    color: var(--negative) !important;
  }
}

.hasSlotLeft.float .label {
  left: calc(#{$inset * 2} + #{$icon-size});
}

.float .input:focus + .label,
.float:not(.empty) .label {
  font-size: $floatLabelSize;
  transform: translateY($floatLabelOffset);
  font-weight: 600;
}

.input {
  @extend %resetInput;
  width: 100%;
  height: $height;
  font-size: max(16px * strip-unit(math.div(font("size"), 100)), 16px);
  line-height: $height;
  padding-left: $inset;
  padding-right: $inset;
  letter-spacing: inherit;
  background-color: $background;
  transition: $transition;
  font-weight: font("weight--bold");
  color: color("text--dark");
  border: 1px solid color("border");
  border-radius: size("border-radius");

  .hasSlotLeft & {
    padding-left: calc(#{$inset * 2} + #{$icon-size});
  }

  .hasSlotRight & {
    padding-right: calc(#{$inset * 2} + #{$icon-size});
  }

  &:enabled {
    &:focus {
      border-color: color("border", -40%);
    }
  }

  .error & {
    border-color: var(--negative);

    &:enabled:focus {
      border-color: var(--negative, -40%);
    }
  }

  &:disabled {
    border: none;
    background-color: $disabledBackground !important;
    color: color("text", 30%) !important;
  }

  .float & {
    padding-top: size("input-padding") * 0.8;

    &:disabled + .label {
      color: color("text", 30%) !important;
    }
  }
}

.errors {
  color: var(--negative);
  font-size: size("font--small");
  position: absolute;
  top: calc(100% - #{$blockPaddingBottom - 0.65rem});
}

.slotLeft,
.slotRight {
  position: absolute;
  z-index: 1;
  bottom: $blockPaddingBottom;
  height: $height;
  display: flex;
  align-items: center;
}

.slotLeft {
  margin-left: size("icon-margin");
  left: 0;
}

.slotRight {
  margin-right: size("icon-margin");
  right: 0;
}

.slot {
  width: $icon-size;
  height: $icon-size;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
}
