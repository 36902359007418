@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$large-block-width: breakpoint("small");

* {
    scroll-behavior: smooth;
}

:target {
    scroll-margin-top: calc((#{(size("header-height") + size("sub-header-height") + size("header-margin"))}));

    @media (max-width: breakpoint("large")) {
        scroll-margin-top: calc((#{(size("header-height") + size("header-margin"))}));
    }
}

.block {
    .wrapper {
        padding: size("gutter--larger");
    }

    ul {
        list-style: initial;
        list-style-type: initial;
    }
    
    li {
        margin-left: size("gutter");
    }

    .italic {
        font-style: italic;
    }

    .bold {
        font-weight: 700;
    }
}

.fullWidth {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: size("gutter--large");

    background-color: var(--light-d1);

    @media (max-width: breakpoint("medium")) {
        flex-direction: column;
    }

    h2,
    h3 {
        margin: size("gutter--small") 0; 
    }

    button {
        font-size: size("font--small");
        padding: size("gutter") !important;
    }

    &__wrapper {
        max-width: breakpoint("large");
        padding-bottom: size("gutter--large");

        .introField {
            padding: size("gutter--large");
            margin-top: size("gutter--large");
            background-color: rgba(255, 255, 255, 0.75);
            border-radius: var(--borderRadius);
        }

        .cards {
            display: flex;
            justify-content: center;
            gap: size("gutter--large");
            
        
            @media (max-width: breakpoint("small")) {
                flex-direction: column;
            }

            .card {
                display: flex;
                flex: 0 1 33.3%;
                min-width: 340px;
                padding: size("gutter");
                gap: size("gutter");
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                box-shadow: 4px 4px 10px rgb(0 0 0 / 10%);
                border-radius: var(--borderRadius);
                background: #fff;
                text-align: center;

                border-top: solid 6px var(--primary);

                img {
                    max-height: 160px;

                    @media (max-width: breakpoint("small")) {
                        max-height: none;
                    }
                }

                .cardContent {
                    display: flex;
                    flex-direction: column;
                    gap: size("gutter");

                    ul {
                        display: inline-flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        align-items: center;
                        gap: size("gutter--medium");
                        margin: 0 size("gutter");
                    }
                }

                .cardLink {
                    font-size: size("font--small");
                    margin-top: size("gutter");
                    padding: size("gutter--small") size("gutter");
                    color: var(--light);
                    background: var(--primary);
                    border-radius: 4px;
                    text-transform: uppercase;
                    text-decoration: none;
                    letter-spacing: .05rem;
                    font-weight: 700;
                    transition: background .1s;

                    &:hover,
                    &:focus {
                        background-color: var(--primary-l1);
                    }
                }
            }
        }

        .largeBlock__borderLeft, .largeBlock__borderTop, .gridBlock {
            padding: size("gutter--large");
            padding-top: size("gutter--medium");
            margin-bottom: size("gutter--larger");
            border-radius: var(--borderRadius);
            background-color: #fff;

            li {
                line-height: 1.4;
            }

            .grid {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: size("gutter--large");
                margin-bottom: size("gutter--large");

                @media (max-width: breakpoint("small")) {
                    grid-template-columns: 1fr;
                }
            }

            .headingBlock {
                display: flex;
                align-items: baseline;
                gap: size("gutter--mini");
                margin: size("gutter--small") 0; 

                svg {
                    width: size("font--large");
                    align-self: center;
                }

                h2, h3 {
                    margin: 0;
                }
            }

            .btnBlock {
                margin-top: size("gutter");
                font-size: size("font--small");
            }
        }

        .largeBlock {

            &__borderLeft, &__borderTop {
                max-width: $large-block-width;
            }

            &__borderLeft {
                border-left: solid 6px var(--primary);
            }

            &__borderTop {
                border-top: solid 6px var(--primary);
            }
        }

        .gridBlockContainer {
            max-width: $large-block-width;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: size("gutter--large");

            @media (max-width: breakpoint("small")) {
                grid-template-columns: 1fr;
            }

            .gridBlock {
                border-top: solid 6px var(--secondary);
            }
        }
    }
}

.hero {
    background-image: url(/assets/wownyckeln/wownyckeln_hero_img.jpeg);
}

.contentField {
    display: flex;
    flex-wrap: nowrap;
    gap: size("gutter--larger");
    justify-content: center;
    align-content: center;

    @media (max-width: breakpoint("small")) {
        flex-direction: column;
    }

    button {
        display: flex;

        @media (max-width: breakpoint("small")) {
            font-size: size("font--mini");
        }

        svg {
            fill: #fff;
            width: 16px;
            margin-right: size("gutter--mini");
        }
    }

    .imgLeft {
        display: flex;
        align-items: center;

        @media (max-width: breakpoint("small")) {
            max-width: 100%;
        }
    }
}