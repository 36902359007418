@import "ui-components/_variables.scss";

$border-color: var(--border);

// Normalize the border-radius to keep things sane
$border-radius: 0px;
@if (size("border-radius") > 0px) {
  $border-radius: 3px;
}

@mixin row {
  display: block;
  color: var(--text);
  padding: 0;
  border: none;
  font-size: 16px;
  border: 1px solid $border-color;
  font-weight: bold;

  padding: 1.1em 1.2em;
  width: 100%;
}

.block {
  display: inline-block;
  user-select: none;
}

.head, .body {
  color: var(--text)
}

.head {
  width: 100%;
  border-radius: $border-radius;
  border: 1px solid $border-color;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  padding: 0;

  &Inner {
    @include row;
    border: none;
    z-index: 1;
  }

  &:hover {
    &Inner, & {
      border-color: color($border-color, 20%);
    }
  }

  .blockOpen.blockDown & {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .blockOpen.blockUp & {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.body {
  position: absolute;
  right: 0;
  left: 0;
  z-index: z-index("popup");

  border: 1px solid $border-color;
  background: var(--light);
  overflow: hidden;

  .blockDown & {
    top: 100%;
    margin-top: -1px;

    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }

  .blockUp & {
    bottom: 100%;
    margin-bottom: -1px;

    border-top-right-radius: $border-radius;
    border-top-left-radius: $border-radius;

    z-index: z-index("overlay");
  }

  .blockOpen & {
    border-top: 1px solid $border-color;
  }
}

.chevronArea {
  flex: 1 0 auto;
  padding: 0.8em 1.2em;
}

.chevron {
  transition: transform 0.2s ease;

  width: 12px;
  height: 12px;

  .blockOpen & {
    transform: scaleY(-1);
  }
}

.searchInput {
  border: none;
  height: 100%;
  padding: 0;
  color: var(--text);
  @include row;
}

.searchIconArea {
  flex: 1 0 auto;
  padding: 0.8em 1.2em;
  display: flex;
  // background-color: #fff;
  border: none;
}

.searchIcon {
  width: 20px;
  height: 20px;
}

.item {
  @include row;
  border-left: none;
  border-right: none;
  border: none;
  text-align: left;
  cursor: pointer;

  border-bottom: 1px solid $border-color;

  &:disabled {
    color: var(--textMuted);
    cursor: not-allowed;
  }

  &:last-child {
    border-bottom: none;
  }

  transition: background transition("duration--molecules") transition("swift-in");

  &:hover, &.active {
    background: var(--primary) !important;
    color: var(--light) !important;
  }
}
