@use "sass:math";

@import "ui-components/_variables.scss";

* {
	scroll-behavior: smooth;
}

:target {
	scroll-margin-top: calc(#{size("header-height")} + #{size("sub-header-height")});
	// For Safari versions 11-14
	scroll-snap-margin-top: calc(#{size("header-height")} + #{size("sub-header-height")});

	@media (max-width: breakpoint("large")) {
		scroll-margin-top: size("header-height");
		scroll-snap-margin-top: size("header-height");
	}
}

.block {
	background: #f6f6f1;

	h1,
	h2,
	h3 {
		margin: size("gutter--mini") 0 !important;
	}

	.fullWidth {

		&__Top,
		&__Bottom {
			width: 100vw;
			background: #fff;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: size("gutter--large");
			margin-bottom: size("gutter--larger");

			p {
				max-width: 60ch;
			}

			h2,
			h3 {
				margin: size("gutter--small");
			}

			button {
				font-size: size("font--small");
				padding: size("gutter") !important;
			}
		}

		&__Top {
			padding: 0;
			position: relative;
			flex-direction: column;
			min-height: 150px;
			background-image: url("/assets/release/roddbat.jpg");
			background-size: cover;
			background-position: center center;
			color: #fff;
			margin: 0;
			max-width: 100%;

			&Wrapper {
				width: 100%;
				max-width: 1240px;
				background-color: rgba(35, 35, 35, 0.6);
				padding: 16px 120px 40px;

				@media (max-width: breakpoint("small")) {
					padding: 16px 20px 40px;
				}
			}
		}

		&__Bottom {
			align-items: flex-start;
			justify-content: center;

			&Wrapper {
				width: 100%;
				max-width: 1240px;
				display: flex;
				flex-direction: row;

				@media (max-width: breakpoint("small")) {
					padding: 16px 20px 40px;
					flex-direction: column;
				}
			}

			@media (max-width: breakpoint("medium")) {
				display: flex;
				flex-direction: column;
			}

			.imgLeft {
				max-width: 40%;

				@media (max-width: breakpoint("small")) {
					max-width: 100%
				}
			}

			.pointsContent {
				margin: 0 0 0 4%;

				@media (max-width: breakpoint("small")) {
					margin: 4% 0 0 0;
				}

				img {
					margin-bottom: size("gutter--larger");
					display: block;
				}

				button {
					display: flex;

					@media (max-width: breakpoint("small")) {
						font-size: size("font--mini");
					}

					svg {
						fill: #fff;
						width: 16px;
						margin-right: size("gutter--mini");
					}
				}
			}
		}
	}
}

.cards {
	margin-bottom: size("gutter--larger");
	padding: 40px 0;
	width: 100vw;

	@media (max-width: breakpoint("small")) {
		padding: 40px 20px;
	}

	h2 {
		text-align: center;
		margin: 0 0 size("gutter--small") !important;
	}

	.cardsContainer {
		display: flex;
		align-items: stretch;
		justify-content: center;
		gap: size("gutter--large");
		margin-top: size("gutter--large");

		@media (max-width: breakpoint("small")) {
			flex-direction: column;
		}
	}

	.card {
		flex: 0 1 33.3%;
		padding-bottom: size("gutter");
		background: #fff;
		display: flex;
		flex-direction: column;
		align-items: center;
		border-radius: 4px;
		text-align: center;

		h3 {
			margin-bottom: size("gutter--small") !important;
		}

		p {
			margin: 0 !important;
		}

		.imgContainer {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: size("gutter--mini");
			margin-bottom: size("gutter--mini") !important;
			background: var(--secondary);
			height: 83px;

			.container {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 40px;
				width: 40px;
			}

			svg {
				max-height: 100%;
				max-width: 100%;
				width: 45px;
				height: 45px;
				fill: #fff;
			}
		}

		.cardLink {
			color: var(--secondary) !important;
			margin-top: size("gutter");
			text-transform: uppercase;
			text-decoration: underline;
		}
	}
}

.bottomBlocksWrapper {
	padding-top: 0;

	.bottomBlocks {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: size("gutter--larger");
		margin-bottom: size("gutter--larger");

		.bottomBlock {
			background: #fff;
			padding: size("gutter");
			border-left: 6px solid var(--secondary);
			display: flex;
			flex-direction: row;
			column-gap: size("gutter");
			width: 100%;

			@media (max-width: breakpoint("medium")) {
				flex-direction: column;
				margin-top: size("gutter--larger");
				padding-top: size("gutter--larger");
			}

			&Wrapper {
				padding: 0 25% 40px size("gutter");
				flex: 1 0 90%;

				@media (max-width: breakpoint("medium")) {
					padding: 0 size("gutter") 40px size("gutter");
					flex: 1 0 100%;
				}
			}

			h2 {
				margin: size("gutter") 0 size("gutter--tiny") !important;
				font-weight: 400;
			}

			&Icon {
				display: flex;
				align-items: flex-start;
				justify-content: center;
				flex: 1 0 10%;

				svg {
					border: 6px solid var(--secondary);
					border-radius: 50%;
					width: 85px;
					height: 85px;
					padding: 15px;
					fill: var(--secondary);
				}
			}

			&Button {
				font-size: size("font--small");
				padding: size("gutter") !important;
			}
		}

		ul {
			margin: size("grid-gutter") 0;
			margin-left: size("gutter");

			li {
				line-height: 1.4;
				padding-left: size("gutter--large");
				margin-bottom: size("gutter--small");
				position: relative;

				&:before {
					content: '';
					width: 16px;
					height: 16px;
					background-image: url('/assets/release/chevron-right.svg');
					background-size: cover;
					display: inline-block;
					position: absolute;
					top: 3px;
					left: 0;
				}
			}
		}
	}
}
