@import "ui-components/_variables.scss";

$image-wrapper-max-height: 240px;
$min-height: 580px;

:export {
  tiny: strip-unit(breakpoint("tiny"));
}

.homeHero {
  width: 100%;
  margin: auto;
  overflow: hidden;
  min-height: $min-height;

  img {
    width: 100%;
    object-fit: cover;
    object-position: top center;
  }

  .image {
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .content {
    display: block;
    width: 100%;
    color: var(--invertedText);
    margin-top: calc(#{size("header-height")} + #{size("header-margin")} + #{size("sub-header-height")});
    padding: 0 0 size("gutter--huge");

    @media (max-width: (breakpoint("medium") - 1px)) {
      margin-top: calc(#{size("header-height")} + #{size("gutter--small")});
      padding: 0 0 size("gutter--large");
    }

    .wrapper {
      padding-top: 0;
    }

    & h1 {
      font-size: size("h1");
      margin-bottom: 1rem;
    }
  }
  .heroIcon {
    max-width: 200px;
  }
}

.currentPageInfoTop {
  display: flex;
  justify-content: center;
  align-items: stretch;
  margin: size("gutter--mini");
  gap: size("gutter");

  @media (max-width: (breakpoint("medium") - 1px)) {
    flex-direction: column;
    align-items: center;
  }
}

.currentInfoDescription {
  font-size: size("font--small");
  max-width: 600px;
}

.currentInfoCardLinkContainer {
  display: flex;
  flex-direction: column;
  justify-content: end;
  height: 100%;

  a {
    display: table;
    margin-top: size("gutter--small");
    width: fit-content;
  }
}

.currentInfo {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: size("gutter");

  @media (max-width: (breakpoint("small") - 1px)) {
    width: 100%;
    grid-template-columns: 1fr;
  }

  > div {
    > div {
      display: flex;
      padding: size("gutter");
      overflow: hidden;
    }
  }
}

.currentInfoCardWrapper {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;

  /* conditions if there is only one InfoCard */
  &:first-child:last-child {
    grid-column: 1/-1;

    .currentInfoCard {
      
      @media (max-width: (breakpoint("medium") - 1px)) {
        max-width: calc(680px + #{size("grid-gutter")});
      }

      @media (max-width: (breakpoint("small") - 1px)) {
        max-width: 510px;
      }
    }
  }
}

.currentInfoCard, .calculatorWrapper, .transactionWrapper, .newsListWrapper  {
  display: flex;
  flex-direction: column;
  background-color: var(--light);
  color: var(--text);
  border-radius: var(--borderRadius);
  box-shadow: 5px 5px 15px 1px rgba(0,0,0,0.1);
  transition: transition("duration--atoms") transition("ease-in");
  max-width: 385px;
  width: 100%;

  @media (max-width: (breakpoint("small") - 1px)) {
    max-width: 510px;
  }

  > div:first-child:not(:last-child){
      width:100%;
  }
}

.currentInfoCard {
  gap: size("grid-gutter");

  h3 {
    margin: 0 0 size("grid-gutter");
    color: var(--primary);
  }

  &ImageWrapper {
    img {
      width:100%;
      max-height: 400px;
      @media (min-width: (breakpoint("medium") - 1px)) {
          max-height: $image-wrapper-max-height;
      }
    }
  } 
}

.calculatorWrapper, .transactionWrapper, .newsListWrapper {
  padding: size("gutter");
  justify-content: space-between;

  @media (max-width: (breakpoint("medium") - 1px)) {
    max-width: calc(680px + #{size("grid-gutter")});
  }

  @media (max-width: (breakpoint("small") - 1px)) {
    max-width: 510px;
  }

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    padding: 0;
  }

  .calculatorBtn {
    font-size: size("font--small");
    background-color: var(--primary);
    color: var(--light);
    padding: size("gutter");
    border-radius: var(--borderRadius);
    margin-top: size("gutter--medium-large");
  }
}

.transactionWrapper {
  padding-top: size("gutter--medium");

  .section__bottom {
    margin-top: size("gutter");
  }
}

.newsListWrapper {

  .newsListTitle {
    margin-bottom: size("gutter");
  }

  .newsItemWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    gap: size("gutter");

    h2 {
      font-size: size("font");
      margin-top: 0;
    }
  }

  .newsListLink {
    display: inline-block;
    margin-top: size("gutter");
  }
}