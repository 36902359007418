@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.block {
  background-color: var(--light);
  color: var(--text);
  padding: size("gutter--medium-large");
  border-radius: var(--borderRadius);
  flex: 1;
}

.noScriptParagraph {
  text-align: center;
  margin-top: size("gutter--medium") !important;
  color: color("negative") !important;
}

.input {
  input {
    background-color: var(--border);
    border: none;
  }
  &:not(:first-child) {
    margin-bottom: size("gutter--mini");
  };
}

.bottom {
  display: flex;
  flex-direction: column;
  margin-top: size("gutter");
}

.button {
  width: 100%;
  background: var(--primary);
  padding: size("gutter--small");
}

.forgot_password, .no_account {
  margin-top: size("gutter--medium");

  p, a {
    margin: 0;
  }

  a {
    color: var(--primary);
    font-weight: bold;
  }
}
