@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$max-wrapper-width: 830px;


.block {
    padding-bottom: size("gutter--huge");
    display: flex;
    justify-content: flex-start;
}