@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$perRowLarge: 7;
$perRowMedium: 7;
$perRowSmall: 4;
$perRowTiny: 3;
$perRowMini: 2;

:export {
    mini: strip-unit(breakpoint("mini"));
    perRowMini: $perRowMini;
    tiny: strip-unit(breakpoint("tiny"));
    perRowTiny: $perRowTiny;
    small: strip-unit(breakpoint("small"));
    perRowSmall: $perRowSmall;
    medium: strip-unit(breakpoint("medium"));
    perRowMedium: $perRowMedium;
    large: strip-unit(breakpoint("large"));
    perRowLarge: $perRowLarge;
}

.logos {
    @include clearfix();
    @include stretch();

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    & > .logo {
        padding: size("grid-gutter");
        display: block;
        float: left;
        text-align: center;

        img {
            width: 100%;
            max-width: 150px;
        }


        @media (min-width: breakpoint("large")) {
            width: math.div(10, $perRowLarge) * 10 + 0%;
        }

        @media (min-width: breakpoint("medium")) and (max-width: (breakpoint("large") - 1px)) {
            width: math.div(10, $perRowMedium) * 10 + 0%;
        }

        @media (min-width: breakpoint("small")) and (max-width: (breakpoint("medium") - 1px)) {
            width: math.div(10, $perRowSmall) * 10 + 0%;
        }

        @media (min-width: breakpoint("tiny")) and (max-width: (breakpoint("small") - 1px)) {
            width: math.div(10, $perRowTiny) * 10 + 0%;
        }

        @media (max-width: (breakpoint("tiny") - 1px)) {
            width: math.div(10, $perRowMini) * 10 + 0%;
        }
    }
}
