@use "sass:math";

@import "ui-components/_variables.scss";

* {
	scroll-behavior: smooth;
}

:target {
	scroll-margin-top: calc(#{size("header-height")} + #{size("sub-header-height")});
	// For Safari versions 11-14
	scroll-snap-margin-top: calc(#{size("header-height")} + #{size("sub-header-height")});

	@media (max-width: breakpoint("large")) {
		scroll-margin-top: size("header-height");
		scroll-snap-margin-top: size("header-height");
	}
}

.block {
	background: #f6f6f1;

	h1,
	h2,
	h3 {
		margin: size("gutter--mini") 0 !important;
	}

	.fullWidth {

		&__Top,
		&__Bottom {
			width: 100vw;
			background: #fff;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: size("gutter--large");
			margin-bottom: size("gutter--larger");

			p {
				max-width: 70ch;
			}

			h2,
			h3 {
				margin: size("gutter--small");
			}

			button {
				font-size: size("font--small");
				padding: size("gutter") !important;
			}
		}

		&__Top {
			padding: 0;
			position: relative;
			flex-direction: column;
			min-height: 150px;
			background-image: url("/assets/dreamteam/englund_top.jpg");
			background-size: cover;
			background-position: center center;
			color: #fff;
			margin: 0;

			&Wrapper {
				width: 100%;
				max-width: 1240px;
				background-color: rgba(35, 35, 35, 0.6);
				padding: 32px 120px 40px;

				@media (max-width: breakpoint("small")) {
					padding: 24px 20px 40px;
				}
			}
		}

		&__Bottom {
			align-items: flex-start;
			justify-content: center;

			&Wrapper {
				width: 100%;
				max-width: 1240px;
				display: flex;
				justify-content: space-between;
				flex-direction: row;

				@media (max-width: breakpoint("small")) {
					padding: 16px 20px 40px;
					flex-direction: column;
				}
			}

			@media (max-width: breakpoint("medium")) {
				display: flex;
				flex-direction: column;
			}

			.imgRight {
				width: 400px;

				@media (max-width: breakpoint("small")) {
					width: 100%
				}
			}

			.pointsContent {
				img {
					width: 600px;
					max-width: 100%;
					margin-top: auto;
					display: block;
				}

				button {
					margin: size("gutter--small") 0 size("gutter--huge");
					display: flex;

					@media (max-width: breakpoint("small")) {
						font-size: size("font--mini");
					}

					svg {
						fill: #fff;
						width: 16px;
						margin-right: size("gutter--mini");
					}
				}
			}
		}
	}
}

.cards {
	margin-bottom: size("gutter--larger");
	padding: 40px 0;
	width: 100vw;

	@media (max-width: breakpoint("small")) {
		padding: 40px 20px;
	}

	h2 {
		text-align: center;
		margin: 0 0 size("gutter--small") !important;
	}

	.cardsContainer {
		display: flex;
		flex-wrap: wrap;
		align-items: stretch;
		justify-content: center;
		margin-top: size("gutter--large");

		@media (max-width: breakpoint("small")) {
			flex-direction: column;
		}
	}

	.card {
		flex: 0 1 33.3%;
		padding: size("gutter--medium");
		padding-bottom: size("gutter");
		display: flex;
		flex-direction: column;
		align-items: center;
		border-radius: 4px;
		text-align: center;

		span {
			color: color("negative");
			font-weight: bold;
		}

		p {
			margin: 0 !important;
			margin-bottom: size("gutter--large") !important;
		}

		.footprintsImg {
			width: 240px;
			height: 204px;
		}

		.imgContainer {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: size("gutter--mini");
			margin-bottom: size("gutter--small") !important;

			.container {
				padding: size("gutter--medium");
				display: flex;
				align-items: center;
				justify-content: center;
				height: 100px;
				width: 100px;
				background: var(--primary);
				border-radius: size("gutter--section");
			}

			svg {
				max-height: 100%;
				max-width: 100%;
				fill: #fff;
			}
		}

		.cardLink {
			border-radius: var(--borderRadius);
			padding: size("gutter--mini");
			margin-top: auto;
			color: #fff;
			background: #242021;
			text-transform: uppercase;
			font-weight: bold;
			font-size: size(font--mini);
		}
	}
}

.bottomBlocksWrapper {
	padding: 0 0 size("gutter--section");
	display: flex;
	align-items: flex-start;
	justify-content: space-between;

	@media (max-width: breakpoint("small")) {
		padding: 16px 20px 40px;
		flex-direction: column;
	}

	img {
		width: 285px;

		@media (max-width: breakpoint("small")) {
			width: 100%;
			margin-top: size("gutter--huge");
		}
	}

	p {
		max-width: 70ch;
	}
}
