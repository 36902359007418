@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$max-wrapper-width: 830px;

.wrapper {
    max-width: $max-wrapper-width;
    border-radius: size("border-radius");

    .inputContainer {
        input {
            border: none !important;
        }
    }

    input, .dropdown {
        margin: 0 0 size("gutter--medium");
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.title {
    margin: size("gutter--small") 0;
}

.description {
    color: var(--textMuted);
    font-size: size("font");
}

.dropdown {
    width: 100%;
    main {
        max-height: 320px;
        overflow-y: scroll;

        button {
            padding: size("gutter--medium");
        }
    }

    button {
        border: none !important;
        background: #fff;
        padding: 0.25rem 0;
    }

    &:last-of-type {
        margin-bottom: 0;
    }
}

.inputContainer {
    padding: size("gutter--large");
    margin-top: size("gutter--medium-large");
    background: #f0f0f0;
    border-radius: 6px;

    .inputWrapper {
        padding-bottom: 0;

        input {
            margin-top: 0;
        }
    }

    input:disabled,
    button:disabled {
        background-color: #e2e2e2 !important;
    }
}

.result[class] {
    margin: size("gutter--medium") 0 0;
    padding: size("gutter--mini") 0;
    font-weight: bold;
}

.error {
    background-color: #f2dede;
    color: #a94442;
    padding: size("gutter--mini") size("gutter--medium");
    border-radius: 4px;
}