@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

:export {
  animationDuration: strip-unit(transition("duration--organisms"));
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.9;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 0.9;
  }
  100% {
    opacity: 0;
  }
}

@keyframes drawerIn {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideDown {
  0% {
    opacity: .7;
    transform: translateY(-100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.outerContainer {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

.container {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;

  z-index: 1;
  transition: transform transition("duration--organisms") transition("ease-out");
  transform: translateX(0);

  &.hamburgerOpen.subNavOpen {
    transform: translateX(-100%);
  }
  &:not(.onHomeView) {
    background-color: var(--primaryAlt);
  }
}

.block {
  flex: 1 0 auto;
  display: flex;
  z-index: 1;
}

.subMenu {
  height: size("sub-header-height");
  background: var(--secondary);
  color: var(--text);
  flex: 1 0 auto;
  display: flex;
  gap: math.div(size("grid-gutter"), 2);
  align-items: center;
  padding-left: 1.2rem;
  padding-right: 1.2rem;

  a {
    font-size: size("font--small");
    button {
      padding: math.div(size("grid-gutter"), 1.5) size("grid-gutter");
    }
  }
}

.close {
  user-select: none;
  text-decoration: underline;
  font-weight: bold;
  flex-shrink: 0;
}

.logo {
  flex: 0 1 auto;

  svg {
    overflow: visible;
  }
}

.wrapper {
  flex: 1 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.FoldableWrapper {
  padding: 0 size("gutter");
}

.left {
  display: flex;
  align-items: center;

  .item:first-child {
    @media (max-width: (breakpoint("small") - 1px)) {
      margin-left: 0;
    }
  }
}

.nav {
  display: flex;
  align-items: center;

  .left & {
    margin-left: size("gutter--large") * 2;
  }
}

.login {
  margin-left: size("gutter--large") !important;

  @media (max-width: (breakpoint("tiny") - 1px)) {
    margin-left: size("gutter--small") !important;
  }
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: size("font--medium");
  font-weight: bold;
  cursor: pointer;
  user-select: none;
  color: var(--primary);

  &:not(:first-child) {
    margin-left: size("gutter--medium");
  }

  span,
  svg {
    color: var(--primary);
  }

  &:hover {
    text-decoration: none;
  }

  &Bright[class] {
    color: var(--primaryAlt);
    font-weight: normal;

    &:last-child {
      border-bottom: none;
    }
  }
}

.name {
  font-size: size("font--medium");
  font-weight: bold;
}

.dropdownButton {
  background: none;
  width: 100%;
  padding: size("gutter") size("gutter");
  border: none;
  border-top: 1px solid rgba(255, 255, 255, 0.4);

  &,
  svg {
    color: var(--primary);
  }

  svg {
    transform: rotate(90deg);
  }

  &.active svg {
    transform: rotate(-90deg);
  }
  &Bright {
    color: var(--primaryAlt);
    justify-content: space-between;
  }
}

.grandTotal {
  color: color("text--muted", 50%);
  font-weight: normal;
  text-transform: none;
  font-size: size("font--small");
}

.cartCount {
  font-size: size("font--mini");
  position: absolute;
  border-radius: 50%;
  background-color: var(--primaryAlt);
  border: 1px solid var(--primary);
  right: -.4rem;
  top: -.2rem;
  font-weight: 400;
  color: var(--primary);
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  top: 0.2rem;
  height: 35px;
  width: 35px;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: size("gutter--small");
  padding-bottom: size("gutter--small");
  height: size("header-height");

  svg path {
    fill: var(--light);
  }
}

.bottomWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: var(--primary);
  padding-top: size("gutter--large") * 1.5;
  padding-bottom: size("gutter--medium");

  .logout {
    font-weight: bold;
    font-size: size(font--medium-small);
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: end;
  }
}

.outofcanvas {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--light);
  height: 100vh;
  overflow-y: auto;
  transition: transform transition("duration--organisms") transition("ease-out");
  transform: translateX(100%);
  opacity: 0;

  .hamburgerOpen & {
    transform: translateX(0%);
    opacity: 1;
  }

  .isOpening & {
    opacity: 1;
  }

  .isClosing & {
    opacity: 1;
    transform: translateX(100%) !important;
  }

  .item {
    margin-left: 0;
    padding: 0 size("gutter");
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    min-height: size("ooc-row-height");

    &:last-child {
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    }

    &,
    svg {
      color: var(--primary);
    }

    &Bright[class] {
      color: var(--primaryAlt);
      font-weight: normal;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  &Main {
    width: 100%;
    flex: 0 0 auto;
  }
}

.dim {
  position: fixed;
  height: 100vh;
  top: size("header-height");
  bottom: 0;
  left: 0;
  right: 0;
  z-index: z-index("system") - 1;
  background: var(--light);
  animation: fadeIn transition("duration--organisms") transition("ease-out") forwards;
}

.miniCart {
  top: size("header-height") !important;
}

.largeHeader:not(.onHomeView) {
  @media (min-width: breakpoint("small")) {
    & > .miniCart,
    & > .dim {
      top: size("header-height") + size("sub-header-height") !important;
    }
  }
}

.closeIcon {
  color: var(--primary);
}

.redeem,
.earn,
.categories,
.brands,
.affiliateCategories {
  background: var(--light);
  position: absolute;
  top: 0px;
  left: 100%;
  right: 0px;
  width: 100%;
  min-height: 100vh;
  display: none;
}

.mode__affiliateCategories .affiliateCategories {
  display: block;
}

.mode__redeem .redeem,
.mode__earn .earn {
  display: block;
}

.user {
  margin-top: size("gutter--large") * 1.5;

  p,
  h3 {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.2;
    // color: var(--invertedText);
  }

  &Name {
    font-weight: bold;
    font-size: size("font--large");
  }

  &Company,
  &Points {
    opacity: .7;
  }

  &Points {
    margin-top: size("gutter--mini") !important;
  }
}

.districtName {
  margin-left: size("gutter--small");
  font-size: size("font--small");
  color: var(--primary);
}

.right {
  flex: 0 0 auto;
}

.chevron {
  transition: transform transition("duration--atoms") transition("ease-in");
  transform: rotate(90deg);
}

.open .chevron {
  transform: rotate(90deg) scaleX(-1);
}

.adminBtnContainer {
  align-self: center;

  button {
    padding: size("gutter--mini") size("gutter") !important;
    font-size: size("font--small") !important;
  }
}
