@import "ui-components/_variables.scss";

.block {
  min-height: 1px;
  min-width: 1px;

  svg {
    overflow: visible;
  }
}

.maxHeight {
  max-height: size("header-height");
  height: size("logo-height");
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    max-height: 100%;
  }
}

.name {
  font-family: font("header");
  font-size: size("font--medium");
}

.svg {
  color: var(--primaryAlt);
}
