@use "sass:math";
@import "ui-components/_variables.scss";

$image-wrapper-max-height: 240px;

:export {
    mini: strip-unit(breakpoint("mini"));
    small: strip-unit(breakpoint("small"));
    medium: strip-unit(breakpoint("medium"));
    large: strip-unit(breakpoint("large"));
}

.block {
    background-color: var(--background);
}

.wrapper {
    margin-top: size("gutter--larger");
    padding-top: size("gutter--large");
    padding-bottom: size("gutter--larger");
}

.carousel {
    width: 100%;
    display: block;
}

.howToEarnImage {
    height: 100%;
    object-fit: cover;
}

.uploadImage {
    .btn {
      padding: size("gutter");
      font-size: size("font--small");
      text-transform: none;
    }

    .cta {
        padding-right: size("gutter--medium");
    }

    @media (max-width: (breakpoint("tiny") - 1px)) {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: size("gutter--medium");
    }
}

.uploadedImageContainer {
  @media (max-width: (breakpoint("mini") + 20px)) {
    flex: 0 0 100%;
  }

  .innerImageContainer {
    height: 290px;
    overflow: hidden;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  .imageContainerTitle {
    max-width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.imageViewDesc {
    margin-bottom: size("gutter--larger");
}

.earnOnlineSection {
    margin-bottom: size("gutter--huge");

    @media (max-width: breakpoint("large")) {
        padding-top: size("gutter--large");
      }
}

.currentPageInfoGrid, .featuredContentSection {
    padding-bottom: size("gutter--huge");
    background-color: var(--border);
    @media (max-width: (breakpoint("medium") - 1px)) {
        .currentInfoCardWrapper {
            max-width: 100%;
        }
    }
}

.currentPageInfoFeatured {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: size("gutter");
    
    @media (max-width: (breakpoint("small") - 1px)) {
        grid-template-columns: 1fr;
    }

    .currentInfoCard {
        padding: size("gutter");
        border-radius: var(--borderRadius);

        img {
            margin-bottom: size("grid-gutter");
            width: 100%;
        }

        .currentInfoCardContent {
            padding: 0;
            height: 100%;
        }

        .currentInfoCardDate {
            margin-bottom: size("grid-gutter");
            font-size: size("font--small");
            color: color("text--muted");
        }

        h3 {
            margin-bottom: size("grid-gutter");
        }

        p {
            margin-top: 0;
        }
    }
}

.featuredContentSection {
    .currentInfoCardContent {
        display: flex;
        flex-direction: column;
    }

    .currentInfoDescription {
        flex: 1;
        p:last-child {
            margin-top: auto;
        }
    }
}

.currentInfoDescription {
    font-size: size("font--small");
    max-width: 600px;
    display: flex;
    flex-direction: column;

    p:last-child {
        justify-self: end;
    }

    a {
        display: table;
        background-color: var(--primary);
        color: var(--light);
        padding: size("gutter--mini") * 1.55 size("gutter") size("gutter--mini") * 1.6;
        margin-top: size("gutter");
        border-radius: var(--borderRadius);
    }
}

.newsListWrapper {
    padding: size("gutter");
    box-shadow: 5px 5px 15px 1px rgb(0 0 0 / 10%);
    margin: size("grid-gutter");
    background-color: var(--light);
    color: var(--text);
    
    & > h2 {
        font-size: size("h2");
        margin-bottom: 0;
        color: var(--primary);
    }

    a {
        padding: 0;
        margin-top: size("gutter");
        border: none;
        word-break: break-word;
        h2 {
            font-size: size("h3");
            margin: size("grid-gutter") 0;
        }
        &> div:first-child {
            display: none;
        }
        * {
            text-align: left;
        }
        header span {
            font-size: size("font--small");
        }
        p {
            font-size: size("font");
        }
    }

    @media (max-width: breakpoint("medium")) {
        padding: size("gutter--mini");
    }
}

.newsListLink:not(#foo) {
    background-color: var(--primary);
    color: var(--light);
    display: inline-block;
    padding: size("gutter--mini") * 1.55 size("gutter") size("gutter--mini") * 1.6;
    font-size: size("font--small");
    line-height: font("line-height--paragraph");
    border-radius: 0;
    box-shadow: none;
    border: none;
    margin-top: auto;
    align-self: flex-start;
}

.currentInfo {
    display: flex;
    flex-wrap: wrap;

    > div {
        > div {
            display: flex;
        }
    }
}

.currentInfoSection {
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    @media (max-width: (breakpoint("medium") - 1px)) {
        flex: 0 0 100%;
    }
}

.currentInfoSectionGrid {
    display: grid;
    grid-template-columns: 7fr 3fr;
    gap: size("gutter");

    @media (max-width: breakpoint("small")) {
        margin-bottom: 0;
        grid-template-columns: 1fr;
    }

    .currentInfoCardWrapper:not(:last-child) {
      margin-bottom: size("gutter");
    }
}

.leadsWrapper {
    padding: 0 !important;

    .currentInfoCardContent {
        padding: size("grid-gutter") size("gutter--small") size("gutter--small") !important;
    }
}

.currentInfoCardWrapper {
    display: flex;
    flex-wrap: wrap;
    min-width: 280px;
    flex: 1 1 auto;

    @media (max-width: (breakpoint("medium") - 1px)) {
        flex: 0 0 100%;
    }

    &Alt {
        min-width: 100%;
        margin: 0;
    }
}

.currentInfoCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--light);
    color: var(--text);
    box-shadow: 5px 5px 15px 1px rgba(0,0,0,0.1);
    flex-grow: 1;
    transition: transition("duration--atoms") transition("ease-in");
    padding: size("gutter");

    img {
        margin-bottom: size("grid-gutter");
    }

    .currentInfoCardContent {
      padding: 0;
    }

    .currentInfoCardDate {
      margin-bottom: size("grid-gutter");
      font-size: size("font--small");
      color: color("text--muted");
    }

    h3 {
      margin-bottom: size("grid-gutter");
    }

    p {
      margin-top: 0;
    }

    > div:first-child:not(:last-child){
        width:100%;
    }
    &ImageWrapper {
        img {
            width:100%;
            max-height: 400px;
            @media (min-width: (breakpoint("medium") - 1px)) {
                max-height: $image-wrapper-max-height;
            }
        }
    }
    &Content {
        padding: size("gutter");
    }
    &Alt {
        padding: size("gutter--large");

        @media (max-width: (breakpoint("small") - 1px)) {
            padding: size("gutter");
        }
    }
}

.currenInfoCardsSection {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: size("gutter");

    @media (min-width: breakpoint("medium")) {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.currenInfoCardImage {
    display: flex;
    justify-content: center;
    margin: size("grid-gutter") 0;
}

.section {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title,
    .description {
        max-width: 600px;
    }
}

.cta {
    padding: size("gutter--small") 0;

    &Large {
        font-size: size("font--large");
    }
}

.top {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: size("title-margin");

    h2 {
        margin-bottom: 0 !important;
    }

    @media (max-width: breakpoint("tiny")) {
        flex-direction: column;
        margin-bottom: size("gutter--large");
    }
}

.title {
    color: var(--text);
}

.featuredProducts,
.earnOnline {
    display: flex;
    justify-content: center;
}

.description {
    font-size: size("font--small");
}

.ingress {
    margin-bottom: 0;
    font-size: size("font--small");
}

@media (max-width: (breakpoint("tiny") - 1px)) {
    .cta,
    .ctaLarge {
        padding-left: 0;
        font-size: size("font") !important;
    }
}

.wrapper {
    margin-top: 0;
    padding-bottom: size("gutter--larger");

    @media (max-width: (breakpoint("tiny") - 1px)) {
        margin-top: size("gutter--huge") 0;
    }

    & > *:not(:first-child) {
        margin-top: size("gutter--huge");
    }
}

.darkBgWrapper {
    padding-top: size("gutter--large");

    .title {
        @media (min-width: (breakpoint("large") - 1px)) {
            padding-top: 0;
        }
    }

    .contentWrapper {
        padding: size("gutter");
        background-color: var(--light);
    }
}

.homeViewLinks {
    display: flex;
    justify-content: space-between;
    display: grid;
    grid-template-columns:repeat(5, 1fr);
    grid-gap: size("gutter");

    @media (max-width: (breakpoint("medium") - 1px)) {
        grid-template-columns:repeat(2, 1fr);
    }

    @media (max-width: (breakpoint("mini") - 1px)) {
        grid-template-columns:repeat(1, 1fr);
    }

    a {
        display: flex;
        justify-content: center;
        background: #fff;
        // width: 180px;
        padding: size("gutter--small") 0;
        box-shadow: 0 6px 15px 0 rgba(0, 0, 0, 0.08);
    }
}

.promotion {
    display: flex;
    background-color: var(--light);
    color: var(--text);
    border-radius: var(--borderRadius);
    box-shadow: 5px 5px 15px 1px rgb(0 0 0 / 10%);
    margin-top: size(gutter--medium);

    .left,
    .right {
        width: 50%;
    }

    .left {
        margin-bottom: 0;
        margin-right: size("gutter--large");

        img {
            border-radius: 0 0 var(--borderRadius) var(--borderRadius);
        }
    }

    .right {
        margin-left: size("gutter--large");
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        padding: size("gutter--large");
    }

    .description {
        margin-bottom: 0;
    }

    .ctaLarge {
        margin-top: size("gutter--large");
        font-size: size("font") !important;
    }

    @media (max-width: (breakpoint("medium") - 1px)) {
        flex-direction: column;

        .left,
        .right {
            width: 100%;
            margin: initial;
        }

        .left {
            &:not(.empty) {
                margin-bottom: size("gutter");
            }
        }
    }
}

.newsButton[class] {
    margin-top: size("gutter--large");
    text-align: center;

    a {
        display: inline-block;
        background-color: #b0b2b9;
        color: var(--light);
        padding: 0.93rem 1.13rem 0.96rem;
        margin-top: 1.13rem;
    }
}

.popularProducts > div > div {
    padding-bottom: size("gutter--huge");

    margin-left: -#{size("grid-gutter")};
    margin-right: -#{size("grid-gutter")};
}

.popularProducts,
.popularCategories {
    @media (max-width: (breakpoint("large") - 1px)) {
        margin-top: size("gutter--large");;
    }
}

@media (max-width: (breakpoint("medium") - 1px)) {
    .popularCategories > div > div {
        padding-bottom: size("gutter--huge");
    }
}

.popularProducts a {
    box-shadow: 3px 3px 7px 1px rgb(0 0 0 / 10%) !important;
}