@use "sass:math";

@import "ui-components/_variables.scss";

.block {
  font-size: size("font--small");
  margin-bottom: size("gutter--larger");
  text-transform: none;
}

.slash {
  margin: 0 0.4rem;
}

.links {
  line-height: font("line-height--breadcrumbs");
  color: var(--text);

  a {
    color: var(--text);
  }
}
