@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$spacing: size("gutter--large") * 3;

:export {
  small: strip-unit(breakpoint("small"));
}

.block {
  display: flex;
  flex-wrap: wrap-reverse;
  @media (min-width: breakpoint("small")) {
    @include stretch();
    @include clearfix();
  }

}

.name,
.brand {
  margin-bottom: size("gutter");
}

.name {
  font-size: size("font--large");
  display: block;
  font-weight: bold;
}

.brand {
  display: inline-block;
  font-size: size("font--medium-small");
  font-weight: bold;
}

.left {
  @media (min-width: breakpoint("small")) {
    padding: 0 math.div(size("grid-gutter"), 2);
    float: left;
    width: 50%;
    padding-right: $spacing;
  }
}

.right {
  width: 100%;

  @media (min-width: breakpoint("small")) {
    padding: 0 math.div(size("grid-gutter"), 2);
    float: right;
    width: 50%;
  }
}

.saleInfo {
  display: flex;
  align-items: baseline;
  gap: size("grid-gutter");

  .prevPrice {
    text-decoration: line-through;
    display: block;
    margin-bottom: size("gutter--mini");
  }

  .salePercent {
    display: inline-block;
    padding: 0.2rem 0.2rem;
    background-color: color("negative");
    color: var(--light);
  }

  & + .price {
    color: color("negative");
  }
}

.qtyPicker,
.addToCart,
.login,
.options,
.options > * {
  @media (max-width: (breakpoint("small") - 1px)) {
    width: 100%;
  }
}

.qtyPicker {
  margin-bottom: size("gutter--large");

  @media (min-width: breakpoint("small")) {
    width: 135px;
  }
}

.estimatedDeliveryDays {
  color: color("text--muted");
}

.action {
  @media (min-width: breakpoint("small")) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  padding: size("gutter--large") 0;

  &:not(:first-child) {
    padding-top: size("gutter--mini");
  }
}

.priceContainer, .price {
  margin-bottom: size("gutter--mini");
}

.price {
  display: block;
  margin-top: 0;
}

.header {
  margin-bottom: size("gutter--large");
}

.points {
  font-size: size("font--medium");
  color: var(--text);
  margin: 0;
}

.description {
  p {
    margin: size("gutter--small") 0;
    font-size: size("font--small") !important;
  }

  iframe {
    max-width: 100%;
  }

  ul {
    line-height: font("line-height--paragraph");
    list-style-type: disc;
    list-style-position: inside;
    margin-bottom: size("gutter");
  }

  li + li {
    margin-top: size("gutter--small");
  }
}

.descriptionHeading {
  margin-bottom: 0;
}

.lists {
  padding-top: size("gutter--large") * 2;
  padding-bottom: size("gutter--large") * 2;

  @media (min-width: breakpoint("small")) {
    @include stretch();
    @include clearfix();
  }
}

.relatedList {
  margin-top: size("gutter--section");
  width: 100%;

  @media (min-width: breakpoint("small")) {
    padding: 0 math.div(size("grid-gutter"), 2);
    float: left;
    width: 50%;
  }
}

.historyList {
  @media (min-width: breakpoint("small")) {
    padding: 0 math.div(size("grid-gutter"), 2);
    float: right;
    width: 50%;
  }

  @media (max-width: (breakpoint("small") - 1px)) {
    margin-top: size("gutter--large");
  }
}

.insufficientFunds {
  margin-bottom: size("gutter");

  h3, p {
    margin: 0;
  }

  p {
    margin: size("gutter--small") 0;
    font-size: size("font--small") !important;
  }
}

.addToCart {
  font-size: size("font--small") !important;
  font-weight: bold;
  text-transform: none !important;
  display: flex;
  justify-content: flex-start;
  padding: size("gutter--small") !important;
  min-width: 290px;
}