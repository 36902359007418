@import "ui-components/_variables.scss";

.block {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.height {
  // height: size("header-height") + size("sub-header-height");
  height: size("header-height");

  .fullMenu & {
    height: size("header-height") + size("sub-header-height");
  }

  @media (max-width: breakpoint("small")) {
    .fullMenu:not(.onEarn) & {
      height: size("header-height");
    }
  }
}

@media (max-width: breakpoint("small")) {
  .hasMobileSubNav .height {
    height: size("header-height") + size("sub-header-height") !important;
  }
}

.onAbout {
  .height {
    height: size("header-height");
  }

  @media (max-width: breakpoint("large")) {
    .children {
      padding: 0;
    }
  }
}

.onHomeView {
  .height {
    display: none;
  }

  .children {
    padding-top: 0;
  }
}

.notices {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: z-index("popup") + 1;
  box-shadow: 0 -6px 36px 0 rgba(0, 0, 0, 0.12);

  > div:not(:first-child) {
    border-top: 1px solid color("border--muted");
  }
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.children {
  display: flex;
  flex-direction: column;
  padding-top: size("gutter--larger");
  background: var(--background);
  margin-bottom: auto;
}

.footer {
  background: var(--primary);
}

.bannerWarning {
  background-color: #fef7f8;
  text-align: center;

  .title[class] {
    margin: 0;
  }

  @media (max-width: 1240px) {
    margin-top: size("gutter--larger") * -1;
    margin-bottom: size("gutter--medium");
  }
}
