@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.verifyEmailView {
  overflow: auto !important;
  width: 100%;
  max-width: 80vh;
  max-height: 80vh;
  padding: size("gutter--large") size("gutter--huge");
  @media (max-width: (breakpoint("medium") - 1px)) {
    min-width: 100vw;
    min-height: 100vh;
  }

  .top {
    display: flex;
    flex-direction: column;
  }

  .header {
    padding-bottom: size("gutter");
    border-bottom: 2px solid var(--primary);
    
    p {
      margin: 0;
    }
  }

  .content {
    margin-top: size("gutter");
  }

  .bottom{
    margin-top: size("gutter--huge");

    display: flex;
    justify-content: center;

    > * {
      margin: 0 size("gutter");
    }
  }

  .tryAgainButton {
    color: var(--primary);
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  @media (max-width: (breakpoint("small") - 1px)) {
    max-width: inherit;
  }

  @media (max-width: (breakpoint("mini") - 1px)) {
    padding: size("gutter--small");
  }
}

@media print {
  .noPrint {
    display: none;
  }
}
