@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$paddingTopLarge: 250px;
$paddingTopSmall: 150px;
$maxWidthLoginForm: 425px;

.block {
  width: 100%;
  height: 100%;
}

.margin {
  margin-top: size("gutter--small") !important;
}

.wrapper {
  flex: 1;
  display: flex;
  align-items: center;
  padding-top: size("gutter--section");
}

.container {
  flex: 1;
  display: flex;
  justify-content: space-between;
  margin: size("gutter--large") -#{size("gutter")} 0;

  .title {
    font-size: size("font--huge");
  }

  .description {
    margin: 0 0 math.div(size("ooc-row-height"), 2);
  }

  @media (max-width: (breakpoint("small") - 1px)) {
    flex-direction: column-reverse;
    margin: 0;
  }

  &__text, &__login {
    padding: 0 size("gutter");

    h2 {
      font-size: size("font--large");
    }

    @media (max-width: (breakpoint("small") - 1px)) {
      width: 100%;
      padding: 0;
      margin-bottom: size("gutter--large");
    }
  }

  &__text {
    color: var(--invertedText);
    flex: 1 1 auto;
    max-width: 36rem;
  }

  &__login {
    flex: 0 0 auto;
    display: flex;
    justify-content: flex-end;
    height: fit-content;
  }
}

.login_form {
  min-width: $maxWidthLoginForm;

  form {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: (breakpoint("small") - 1px)) {
    min-width: 0;
    width: 100%;
  }
}

.footer {
  display: flex;
  justify-content: center;
  padding: size("gutter") 0;
  color: var(--invertedText),
}

