@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

@keyframes shine {
  0% {
    background-position: -100%;
  }
  100% {
    background-position: 100%;
  }
}

.block {
  flex: 1;
  display: block;

  &:not(.count__1)  {
    box-shadow: 5px 5px 15px 1px rgb(0 0 0 / 10%);

    .wrapper {
      padding: size("gutter--large");
      background-color: var(--light);
    }
  }
}

.top {
  text-align: right;
}

.title  {
  font-family: var(--fontBody), var(--fontBodyFallback, #{font("fallback")});
  color: var(--text);
  margin-top: size("gutter");
  margin-bottom: size("gutter");
  font-size: size("font--large");
}

.content {
  font-size: size("font--medium-small");
  line-height: font("line-height--medium-small");

  // limit content
  $description-rows: 3;
  overflow: hidden;
  height: #{#{$description-rows * font("line-height")}em};

  &:after {
    content: "";
    display: block;
    width: 8ch;
    height: 1em * font("line-height");
    background-image: linear-gradient(to left, rgba(var(--light), 1), rgba(var(--light), 0));
    pointer-events: none;
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.date, .content {
  color: var(--textMuted) !important;
}

.text {
  font-size: size("font--small");
  max-width: 600px;
  a {
      display: table;
      background-color: var(--primary);
      color: var(--light);
      padding: size("gutter--mini") * 1.55 size("gutter") size("gutter--mini") * 1.6;
      margin-top: size("gutter") * 2;
      border-radius: var(--borderRadius);
  }
}

.dummy {
  &_block {
    flex: 1;
    width: 100%;
    border: 1px solid var(--border);
    border-radius: var(--borderRadius);
    padding: size("gutter--large");

    @media (max-width: (breakpoint("medium") - 1px)) {
      padding: size("gutter");
    }
  }

  &_top {
    display: flex;
    justify-content: flex-end;
  }

  &_title {
    margin-top: size("gutter");
    margin-bottom: size("gutter");
    height: size("font--large");
    width: 100%;
    max-width: 100%;
    background-image: linear-gradient(90deg, color("disabled") 0%, color("background") 50%, color("disabled") 100%);
    background-size: 200%;
    animation: shine 1.5s infinite linear;
  }

  &_content {
    height: size("font") * 4;
    width: 100%;
    max-width: 100%;
    background-image: linear-gradient(90deg, color("disabled") 0%, color("background") 50%, color("disabled") 100%);
    background-size: 200%;
    animation: shine 1.5s infinite linear;
  }
}
