
@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.item {
  min-width: 0;
  flex: 0 0 25%;
  padding: size("grid-gutter");

  &:first-of-type:not(:only-of-type) {
    padding-left: 0 !important;
  }

  &:last-of-type:not(:only-of-type) {
    padding-right: 0 !important;
  }

  @media (max-width: (breakpoint("medium") - 1px)) {
    flex: 0 0 33.333%;
  }

  @media (max-width: (breakpoint("small") - 1px)) {
    flex: 0 0 50%;
  }
}
