@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$pagination-btn-size: 2.5rem;

.wrapper {
  background: #fff;
  padding: size("gutter--large");

  @media (min-width: breakpoint("tiny")) {
    .link {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  @media (max-width: breakpoint("tiny")) {
    .districtSelector {
      margin-top: size("gutter--large");
    }
  }
}

.paginationWrapper {
  display: flex;
  justify-content: center;
}

.pagination {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: size("gutter--large") 0;

  span {
    padding: 0 0.5rem;
  }

  &Button {
    display: flex;
    justify-content: center;
    align-content: center;
    background: var(--primary);
    color: var(--light);
    line-height: $pagination-btn-size;
    border: 0;

    height: $pagination-btn-size;
    min-width: $pagination-btn-size;

    &.active {
      background: var(--primary-d2);
      text-decoration: underline;
    }

    &:first-child {
      border-top-left-radius: $pagination-btn-size;
      border-bottom-left-radius: $pagination-btn-size;
    }

    &:last-child {
      border-top-right-radius: $pagination-btn-size;
      border-bottom-right-radius: $pagination-btn-size;
    }
  }
}