@import "ui-components/_variables.scss";


.transactionsTable {
  width: 100%;
  margin: 0 !important;

  tbody {
    border: none;
  }

  thead {

    th {
      padding-bottom: size("gutter--medium-large");
      color: color("primary")
    }
  }

  th {
    text-align: left;
  }

  tr {
    &:not(:first-of-type) {
      border-top: 1px solid var(--border);
    }

    td {
      padding: calc(#{size("gutter--mini")} * 1.5) 0;
    }
  }

  .negative {
    color: var(--negative);
  }
}
