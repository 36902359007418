@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.notice {
  background-color: var(--primary);
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: breakpoints("medium")) {
    height: 120px;
  }
}

.content {
  font-size: size("text--small");
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  max-width: 1244px;
  padding: size("gutter--small") size("gutter");

  .icon {
    color: var(--primary);
    min-width: 24px;
  }

  .buttons {
    display: flex;
  }

  .button {
    white-space: nowrap;
    color: var(--primary);
    padding: size("gutter--mini") size("gutter--small");
    margin: 0 size("gutter--mini");

    &__all {
      background-color: var(--light);
    }

    &__necessary {
      background-color: var(--secondary);
      color: var(--invertedText);
    }
  }

  .text {
    white-space: pre-wrap;
    margin: 0 size("gutter");
    color: var(--invertedText);

    a {
      border-bottom: 1px dotted var(--primary);
      color: var(--primary);

      &:hover, &:focus {
        border-bottom: none;
        text-decoration: underline;
        background-color: transparent;
      }

      &:focus {
        outline-style: auto;
        outline-width: 1px;
        outline-color: var(--primary);
        box-shadow: none;
      }
    }
  }

  .close {
    cursor: pointer;
    white-space: nowrap;
    flex: 0 0 auto;
  }

  @media (max-width: breakpoint("tiny")) {
    font-size: size("font--mini");
    flex-direction: column;
    align-items: flex-start;

    .text {
      margin: size("gutter") 0;
    }
  }
}
