@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$pagination-btn-size: 2.5rem;




.wrapper {
  background: #fff;
  padding: size("gutter--large");

  .intro {
    background: #fff;
    margin-bottom: size("gutter--large");

    .link {
      position: absolute;
      top: 0;
      right: 0;
    }

    .link+.link {
      position: absolute;
      top: size("gutter--large") * 1.5;
      right: 0;
    }
  }

  .overview {
    margin: size("gutter--huge") 0;

    .overviewBar {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      section {
        display: flex;
        align-items: center;
        margin-right: size("gutter--large");

        .score {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 55px;
          height: 55px;
          background-color: var(--primary);
          margin-right: 10px;
          border-radius: 50%;
          color: var(--light);
        }

        p {
          margin-top: 0;
        }

        &:nth-child(3) {
          margin-left: auto;
          margin-right: 0;
        }
      }

      .dropdown {
        width: 250px;
        border-radius: var(--borderRadius);

        button .head {
          background: #aaa !important;
        }
      }
    }
  }
}

.paginationContainer {
  margin: size("gutter--large") 0;

  .paginationButton {
    display: inline-flex;
    justify-content: center;
    align-content: center;
    background: #eee;
    color: var(--primary-d2);
    line-height: $pagination-btn-size;
    border-radius: var(--borderRadius);
    margin-right: size("gutter--mini");
    border: none;

    height: $pagination-btn-size;
    width: $pagination-btn-size;

    &:hover {
      background: var(--primary-d3);
      color: #fff;
    }

    &:first-child,
    &:last-child {
      display: none;
    }
  }

  .active {
    background: var(--primary-d3);
    color: #fff;
  }
}

.contentWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: size("gutter--large")
}

.formWrapper {
  width: 100%;

  form {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    @media (max-width: breakpoint("small")) {
      flex-direction: column;
    }

    .reportWrapper {
      width: calc(70% - #{size("gutter--medium")});
      display: flex;
      flex-direction: column;
      gap: size("gutter--small");
      background: #eee;
      padding: size("gutter--medium");
      border-radius: size("border-radius");

      @media (max-width: breakpoint("small")) {
        width: 100%;
      }

      h2 {
        margin-bottom: 0;
      }

      .formTop {
        align-items: flex-end;
      }

      section {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: size("gutter--small");

        @media (max-width: breakpoint("small")) {
          flex-direction: column;

          &>div {
            width: 100% !important;
          }
        }

        &>div {
          width: 50%;
        }

        input {
          border: none;
          border-radius: size("border-radius");
        }

        .DropdownWrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          @media (max-width: breakpoint("medium")) {
            margin-bottom: size("gutter--large");
          }

          .selector {
            div {
              padding: size("gutter--medium");
              padding-left: 1rem;
              border-radius: var(--borderRadius);
            }

            button {
              border: none;
              background: #fff;
            }
          }

          label {
            margin-bottom: size("gutter--mini");
          }

          &>div {
            width: 100% !important;
          }
        }

        .countWrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: size("gutter--medium");

          @media (max-width: breakpoint("medium")) {
            &>div {
              width: 50% !important;
            }
          }

          button {
            width: 50%;
            padding: size("gutter--medium");
            background: var(--primary);
            color: #fff;
          }
        }

      }

    }
  }
}


.orderWrapper {
  width: 30%;

  @media (max-width: breakpoint("small")) {
    width: 100%;
    margin-top: size("gutter--medium");
  }

  .orderItem {
    background: #eee;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: size("gutter--small");
    margin-bottom: size("gutter--medium");
  }

  button {
    width: 100%;
    padding: size("gutter--small");
    background: var(--primary);
    color: #fff;
  }

  button[disabled] {
    background: #eee;
    color: rgba(var(--text), .35);
  }

  .qty {
    margin-left: size("gutter--small");
  }

  .closeIcon {
    margin-left: auto;
    cursor: pointer;
  }
}

.districtSelector {
  margin-bottom: size("gutter--medium");
}