@import "ui-components/_variables.scss";

$padding: size("gutter--large");
$padding--small: 1.2rem;

.wrapper {
  margin-top: size("gutter--larger");
  padding-top: size("gutter--large");
  padding-bottom: size("gutter--larger");
}

.tele2LinksContainer[class] {
  display: flex;
  justify-content: space-between;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: size("gutter");

  @media (max-width: (breakpoint("medium") - 1px)) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: (breakpoint("mini") - 1px)) {
    grid-template-columns: repeat(1, 1fr);
  }

  a {
    display: flex;
    justify-content: center;
    background: #fff;
    padding: size("gutter--small") 0;
    color: var(--primary);
    box-shadow: 0 6px 15px 0 rgba(0, 0, 0, 0.08);
  }
}

.releaseLinksContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: size("gutter");
  flex-wrap: wrap;

  .releaseNavItem {
    padding: size("gutter--small") size("gutter--medium");
    background: #f0f0f0;
    display: flex;
    /* Account for gap */
    flex: 1 1 calc(33.3% - #{size("gutter")});
    max-width: 390px;
    min-width: 320px;
    min-height: 160px;

    @media (max-width: (breakpoint("medium") - 1px)) {
      /* Account for gap */
      flex: 1 1 calc(50% - (#{size("gutter")} / 2));
    }

    svg {
      width: 80px;
      align-self: center;
    }

    .itemContent {
      width: calc(100% - 80px);
      display: flex;
      flex-direction: column;
      margin-left: size("gutter--large");

      .title {
        font-size: size("font--large");
        font-weight: bold;
        margin-bottom: size("grid-gutter");
      }

      p {
        margin: 0;
        margin-bottom: size("gutter--mini");
      }

      a {
        margin-top: auto;
      }
    }
  }
  /* if only two items */
  .releaseNavItem:first-child:nth-last-child(2),
  .releaseNavItem:first-child:nth-last-child(2) ~ .releaseNavItem {
    flex: 1 1 calc(50% - (#{size("gutter")} / 2));
  }
}
