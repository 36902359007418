@use "sass:math";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$contactInfoPaddingTop: 1.8rem;

.accountView {
  padding-bottom: size("gutter--huge");
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  button {
    margin-bottom: size("gutter--medium");
  }
}

.newsletter {

  .consentContent {
    padding: size("gutter--small") 0;
  }

  .checkboxContainer {
    display: block;
  }

  .checkbox {
    padding: size("gutter--small") 0;

    label {
      margin-bottom: 3px;
    }
  }
}

.title {
  margin: size("gutter--medium") 0 !important;
}

.disabled {
  color: #aaa !important;

  &:hover {
    text-decoration: none !important;
  }
}

.container {
  @media (min-width: breakpoint("small")) {
    display: flex;
    @include stretch(math.div(size("gutter"), 2));
    @include clearfix();
  }
}

.left {
  @media (min-width: breakpoint("small")) {
    padding: 0 math.div(size("gutter"), 2);
    float: left;
    flex: 0 1 60%;
  }
}

.right {
  margin-top: size("gutter--large");
  @media (min-width: breakpoint("small")) {
    margin-top: 0;
    padding: 0 math.div(size("gutter"), 2);
    float: right;
    flex: 1 0 40%;
    width: 40%;
  }
}

.form {
  transition: opacity transition("duration--atoms") transition("ease-in");
  &.saving {
    opacity: .5;
  }
}

.box {
  box-shadow: 5px 5px 15px 1px rgb(0 0 0 / 10%);
  background-color: var(--light);

  strong {
    font-size: size("font");
  }

  &Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: size("gutter");

    .userDetails & {
      border-bottom: 1px solid var(--border);
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      background: none;
      border: none;
      max-width: fit-content;
      color: var(--primary);

      svg {
        margin-left: math.div(size("gutter--mini"), 2);
        min-width: 24px;
      }
    }

    button:not(.saveButton) {
      &:hover {
        text-decoration: underline;
      }
    }

    .saveButton {
      background-color: var(--primary);
      color: var(--invertedText);
      border-radius: var(--borderRadius);
      padding: size("gutter--mini") size("gutter");
      padding-left: calc(#{size("gutter")} + 4px);
      transition: background-color transition("duration--organisms") transition("ease-ease");

      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }

      &:hover:not(:disabled) {
        background-color: var(--primary-l1);
      }
    }
  }

  &Data {
    padding: size("gutter");
    cursor: not-allowed;
    opacity: 0.5;

    .dataCheckbox {
      pointer-events: none;
    }

    p {
      padding: size("gutter") 0;
    }
  }

  &Body {
    transition: opacity transition("duration--atoms") transition("ease-in");

    .saving & {
      opacity: .5;
    }

    padding: size("gutter");

    .password & {
      border-top: 1px solid var(--border);
    }

    &.removeBottomPadding {
      padding-bottom: 0;
    }
  }

  &Heading {
    font-size: size("font--large");
    font-weight: 800;
    margin-bottom: 0;
  }
}

.boxHeaderConsent {
  border-bottom: 1px solid var(--border);
}

.row + .row,
.container + .container {
  margin-top: size("gutter--large");
  @media (min-width: breakpoint("small")) {
    margin-top: size("gutter");
  }
}

.inputSplit {
  display: flex;
  flex-wrap: wrap;
  @include stretch(math.div(size("gutter"), 2));

  & > * {
    flex: 1 0 50%;
    padding: 0 math.div(size("gutter"), 2);

    @media (max-width: (breakpoint("tiny") - 1px)) {
      flex: 1 0 100%;
    }
  }
}

.pointsWrapper {
  white-space: nowrap;
}

.pointsTable {
  width: 100%;
  tr:not(:last-child) td {
    padding-bottom: size("gutter--small");
  }

  td:last-child {
    text-align: right;
  }

  .boldBalance {
    font-weight: bold;
  }
}

.transactionsTable,
.additionalMemberNumbersTable {
  @include stretch(size("gutter"));

  thead th {
    padding: size("gutter--small");
    padding-top: 0;
    font-weight: normal;
    font-size: size("font--small");
    text-align: left;
  }

  tr td {
    border-top: 1px solid var(--border);
    padding: size("gutter--small");
  }

  tr:last-child td {
    padding-bottom: 0;
  }

  .negative {
    color: var(--negative);
  }
}

.contactInfo {
  @media (max-width: (breakpoint("small") - 1px)) {
    padding-top: $contactInfoPaddingTop;
  }
}

.user {
  &Slab {
    opacity: 0.75;
  }

  &Separator {
    margin: 0 math.div(size("gutter--small"), 4);
  }
}
